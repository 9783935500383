import { useCallback, useEffect, useRef, useState } from "react";
import {
  COLORS,
  Size,
  SystemIcons,
  TextField,
} from "@laerdal/life-react-components";
import Picker from "react-mobile-picker";
import dayjs from "dayjs";
import isToday from "dayjs/plugin/isToday";
import DateTimePickerManipulation, {
  DatePickerObject,
} from "../helpers/DateTimePickerManipulation";
import { DatePickerDateType } from "../helpers/Enums";
import { LocalStorageKey, useLocalStorage } from "../helpers/LocalStorage";
import "../../resources/styles/DateTimePicker.css";
import useLabourViewStore from "../hooks/useLabourViewStore";

// Defines the properties for managing time input focus and state synchronization with a parent component
interface Props {
  // Flag to remove focus from the time input field
  removeFocusFromTimeInput: boolean;
  // Function to update the parent's state regarding focus removal
  setRemoveFocusInParentComponent: (newValue: boolean) => void;
  // Optional function to update the parent with the latest selected time
  setSelectedLatestTime?: (newValue: string) => void;
  // Indicates if the component is used within a modal
  isInModal?: boolean;
  // Optional flag to allow or disallow picking future dates and times
  canPickFutureDateTime?: boolean;
}

// Generates a list of Picker items with conditional styling based on selection state
function renderOptions(options: string[]) {
  return options.map((option) => (
    <Picker.Item key={option} value={option}>
      {({ selected }) => (
        <div className={selected ? "selected-value" : "unselected-value"}>
          {option}
        </div>
      )}
    </Picker.Item>
  ));
}

// DateTimePicker component manages a date-time input field with focus and blur handling, time refresh logic,
// and synchronization with the parent component's state. It displays a date-time picker that updates its values
// based on user interaction and inactivity timers.
const DateTimePicker = ({
  removeFocusFromTimeInput,
  setRemoveFocusInParentComponent,
  setSelectedLatestTime,
  isInModal,
  canPickFutureDateTime = true,
}: Props) => {
  // Variables

  // Tracks whether the input field has been touched or focused by the user
  const [isInputTouched, setIsInputTouched] = useState(false);

  // Stores the timestamp when the input gains focus, retrieved from local storage
  const [inputWithFocusTimestamp, setInputWithFocusTimestamp] = useLocalStorage(
    LocalStorageKey.InputWithFocusTimestamp,
    ""
  );

  // Stores a reference to the date-time picker element for direct DOM access
  const dateTimePickerRef = useRef<HTMLInputElement | null>(null);

  // Number of minutes to wait before resetting the time picker after input inactivity
  const waitingMinutesBeforeResettingTimer = 10;

  // Manages the current value of the date-time picker
  const [pickerValue, setPickerValue] = useState<DatePickerObject>(
    DateTimePickerManipulation.getCurrentTimeDateTimePickerValue()
  );

  // State to control the visibility of the date-time picker
  const [isDateTimePickerVisible, setIsDateTimePickerVisible] = useState(false);

  // State to store the dropdown's dynamic width in pixels
  const [dropdownWidth, setDropdownWidth] = useState<number>(0);

  // State manager to control whether the date-time picker is active
  const setIsDateTimePickerActive = useLabourViewStore(
    (state) => state.setIsDateTimePickerActive
  );

  // Extend Day.js with the isToday plugin
  dayjs.extend(isToday);

  // Functions

  // Toggles the visibility and activation state of the date-time picker
  const toggleDateTimePickerVisibility = useCallback(
    (shouldBeVisible: boolean) => {
      // Set activation state only if not in a modal
      if (!isInModal) {
        setIsDateTimePickerActive(shouldBeVisible);
      }
      // Update visibility of the date-time picker
      setIsDateTimePickerVisible(shouldBeVisible);
    },
    [isInModal, setIsDateTimePickerActive]
  );

  // Handles the focus event for the input field by setting touch state and timestamp
  const handleFocusEvent = () => {
    // Mark the input as touched
    setIsInputTouched(true);
    // Store the current timestamp in local storage for inactivity tracking
    setInputWithFocusTimestamp(dayjs());
    // Ensure the date-time picker is visible
    toggleDateTimePickerVisibility(true);
  };

  // Updates the date-time picker values based on the provided dayjs instance
  const setDateTimePickerValues = useCallback(
    (newValue: dayjs.Dayjs) => {
      setPickerValue({
        day: newValue.isToday()
          ? DatePickerDateType.Idag
          : DatePickerDateType.Igar,
        hour: DateTimePickerManipulation.formatHoursMinutes(newValue.hour()),
        minute: DateTimePickerManipulation.formatHoursMinutes(
          newValue.minute()
        ),
      });
    },
    [setPickerValue]
  );

  // Handles changes in the date-time picker and updates state accordingly
  const handlePickerChangeValues = (value: DatePickerObject) => {
    // Update the date-time picker values with the converted dayjs instance
    const pickerValueDayJs =
      DateTimePickerManipulation.transformPickerValueIntoDayJSObject(value);
    setDateTimePickerValues(pickerValueDayJs);
    // Optionally update the parent's state with the new selected time
    setSelectedLatestTime?.(pickerValueDayJs.toString());
  };

  // Refreshes the time input values and updates the parent component's state
  const refreshTimeInput = useCallback(
    (time: dayjs.Dayjs) => {
      // Update the date-time picker with the new time value
      setDateTimePickerValues(time);
      // Optionally update the parent's state with the new time value
      setSelectedLatestTime?.(time.toString());
    },
    [setSelectedLatestTime, setDateTimePickerValues]
  );

  // Resets the date-time picker and updates state when the input loses focus
  const resetDateTimePickerOnBlur = useCallback(
    (time: dayjs.Dayjs) => {
      // Mark input as not touched
      setIsInputTouched(false);
      // Hide the date-time picker
      toggleDateTimePickerVisibility(false);
      // Refresh the time input values and update the state
      refreshTimeInput(time);
    },
    [toggleDateTimePickerVisibility, refreshTimeInput]
  );

  // Handles clicks outside the date-time picker to hide it
  function useOutsideClickHandler(ref: React.RefObject<HTMLElement>) {
    useEffect(() => {
      // Event handler to check if the click is outside the component
      const handleMouseDown = (event: MouseEvent) => {
        // If ref is set and the click is outside the component
        if (ref.current && !ref.current.contains(event.target as Node)) {
          // Hide the component
          toggleDateTimePickerVisibility(false);
        }
      };

      // Register event listener on the document
      document.addEventListener("mousedown", handleMouseDown);
      // Cleanup function to remove the event listener
      return () => document.removeEventListener("mousedown", handleMouseDown);
    }, [ref]);
  }

  // Sets the dropdown width to match the date-time picker container on mount or when it changes
  useEffect(() => {
    if (dateTimePickerRef.current) {
      // Get the current width of the date-time picker container
      const width = dateTimePickerRef.current.offsetWidth;
      // Update the dropdown width to match the date-time picker container
      setDropdownWidth(width);
    }
  }, [dateTimePickerRef.current?.offsetWidth]);

  // Manages input focus state and refreshes time input based on inactivity
  useEffect(() => {
    // Set up an interval to manage input focus state and refresh time input
    const intervalId = setInterval(() => {
      const currentTime = dayjs();

      // If the input is focused, check if the allowed inactive time has passed
      if (isInputTouched) {
        // Define the expiration time based on the focus timestamp
        const focusExpirationTime = inputWithFocusTimestamp.add(
          waitingMinutesBeforeResettingTimer,
          "minutes"
        );

        // If the current time exceeds the expiration time, reset the date-time picker
        if (currentTime >= focusExpirationTime) {
          resetDateTimePickerOnBlur(currentTime);
        }
      } else {
        // Refresh time input if not touched
        refreshTimeInput(currentTime);
      }
    }, 1000);

    // If the focus should be removed, reset relevant states
    if (removeFocusFromTimeInput) {
      // Mark input as not touched
      setIsInputTouched(false);
      // Update parent component's focus removal flag
      setRemoveFocusInParentComponent(false);
      // Clear the focus timestamp
      setInputWithFocusTimestamp("");
    }

    // Clean up interval on component unmount
    return () => clearInterval(intervalId);
  }, [
    inputWithFocusTimestamp,
    isInputTouched,
    refreshTimeInput,
    removeFocusFromTimeInput,
    resetDateTimePickerOnBlur,
    setInputWithFocusTimestamp,
    setRemoveFocusInParentComponent,
    setSelectedLatestTime,
  ]);

  // Ensures the selected picker time is not set to a future date or time; if it is, reset to the current time
  useEffect(() => {
    if (canPickFutureDateTime) {
      // Convert the picker value to a Day.js instance
      const selectedPickerTime =
        DateTimePickerManipulation.transformPickerValueIntoDayJSObject(
          pickerValue
        );

      // Get the current time
      const currentTime = dayjs();

      // Reset picker to current time if future time is selected
      if (selectedPickerTime.isAfter(currentTime)) {
        setDateTimePickerValues(currentTime);
      }
    }
  }, [canPickFutureDateTime, pickerValue, setDateTimePickerValues]);

  // Attach a handler to close the picker when clicking outside of it
  useOutsideClickHandler(dateTimePickerRef);

  return (
    <div className="date-time-picker-container" ref={dateTimePickerRef}>
      <div className="picker-input-container">
        {/* Text field for displaying and interacting with time input */}
        <TextField
          id="DateTimePickerInputField"
          size={Size.Small}
          value={`${pickerValue.hour}:${pickerValue.minute}`}
          onFocus={() => handleFocusEvent()}
          inputMode="none"
        />
        <div className="clock-icon">
          <SystemIcons.Time color={COLORS.neutral_600} />
        </div>
      </div>
      {/* Date-time picker component, visible based on state */}
      {isDateTimePickerVisible && (
        <div>
          <Picker
            className="picker-options-container"
            value={pickerValue}
            onChange={handlePickerChangeValues}
            wheelMode="natural"
            style={{ width: dropdownWidth }}
          >
            <Picker.Column name="day">
              {renderOptions(DateTimePickerManipulation.getDateValues())}
            </Picker.Column>
            <Picker.Column name="hour">
              {renderOptions(
                DateTimePickerManipulation.generateNumberArray(24, true)
              )}
            </Picker.Column>
            <Picker.Column name="minute">
              {renderOptions(
                DateTimePickerManipulation.generateNumberArray(60, true)
              )}
            </Picker.Column>
          </Picker>
        </div>
      )}
    </div>
  );
};

export default DateTimePicker;
