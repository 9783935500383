import { useState } from "react";

export enum LocalStorageKey {
  SlowProgressTime = "slow-progress-time",
  RegisteredLatestTime = "registered-latest-time",
  RegisteredCervixOpening = "registered-cervix-opening",
  InputWithFocusTimestamp = "input-with-focus-timestamp",
  AddedHoursToSlowProgress = "added-hours-to-slow-progress",
  LabourProgressDataArray = "labour-progress-data-array",
}

export const useLocalStorage = (key: LocalStorageKey, initialValue: any) => {
  // Retrieve data from local storage on component mount
  const storedValue = localStorage.getItem(key);
  let initial;
  try {
    initial = storedValue ? JSON.parse(storedValue) : initialValue;
  } catch (error) {
    console.log(`Error parsing localStorage key "${key}":`, error);
    initial = initialValue; // Fallback to initial value if error
  }
  
  // State to hold the current value
  const [value, setValue] = useState(initial);

  // Update local storage and state when the value changes
  const updateValue = (newValue: any) => {
    try {
      setValue(newValue);
      localStorage.setItem(key, JSON.stringify(newValue));
    } catch (error) {
      console.log(`Error setting localStorage key "${key}":`, error);
    }
  };

  return [value, updateValue];
};

export const getValueFromLocalStorageByKey = (
  key: LocalStorageKey,
  initialValue: any
) => {
  const storedValue = localStorage.getItem(key);
  try {
    return storedValue ? JSON.parse(storedValue) : initialValue;
  } catch (error) {
    console.log(`Error parsing localStorage key "${key}":`, error);
    return initialValue; // Fallback to initial value if error
  }
};

export const removeKeyFromLocalStorage = (key: LocalStorageKey) => {
  localStorage.removeItem(key);
};

export const refreshLabourDataFromLocalStorage = () => {
  return getValueFromLocalStorageByKey(
    LocalStorageKey.LabourProgressDataArray,
    []
  );
};
