import { SystemIcons } from "@laerdal/life-react-components";
import InfoBoxModal from "./modals/InfoBoxModal";
import { useState } from "react";

const NavigationBar = () => {
  // Variables
  const [openInfoBoxModalWindow, setOpenInfoBoxModalWindow] = useState(false);

  return (
    <>
      <header>
        <div className="title-container">LabourView</div>
        <div
          className="info-container"
          onClick={() => setOpenInfoBoxModalWindow(true)}
        >
          <SystemIcons.Information size="24px" />
        </div>
      </header>
      <InfoBoxModal
        isWindowOpen={openInfoBoxModalWindow}
        setIsWindowOpen={setOpenInfoBoxModalWindow}
      />
    </>
  );
};

export default NavigationBar;
