import { useState } from "react";
import { Button, ModalDialog, Size } from "@laerdal/life-react-components";
import useLabourViewStore from "../hooks/useLabourViewStore";
import "../../resources/styles/NewBirth.css";

const NewBirth = () => {
  // Variables
  const [openResetDataModalWindow, setOpenResetDataModalWindow] =
    useState(false);

  // Global state managers

  // State manager for resetting the calculator data.
  const setResetCalculatorData = useLabourViewStore(
    (state) => state.setResetCalculatorData
  );

  // State manager for resetting the visualization data.
  const setResetVisualizationsData = useLabourViewStore(
    (state) => state.setResetVisualizationsData
  );

  // State manager for refreshing labour progress data from storage.
  const setRefreshLabourProgressDataFromStorage = useLabourViewStore(
    (state) => state.setRefreshLabourProgressDataFromStorage
  );

  return (
    <>
      <div className="new-patient-bar-container">
        <div className="bottom-bar-container">
          <Button
            className=""
            variant="critical"
            size={Size.Medium}
            width="146px"
            onClick={() => {
              // Opening the Reset data modal window
              setOpenResetDataModalWindow(true);
            }}
          >
            Ny fødsel
          </Button>
        </div>
      </div>
      <ModalDialog
        key="AvsluttModalDialog"
        title={"Vil du slette data fra denne fødselen?"}
        size={Size.Medium}
        isModalOpen={openResetDataModalWindow}
        closeAction={() => {
          setOpenResetDataModalWindow(false);
        }}
        submitAction={() => {}}
        buttons={[
          {
            action: () => {
              // Closing the Reset data modal window
              setOpenResetDataModalWindow(false);
            },
            text: "Nei, fortsett",
            variant: "tertiary",
          },
          {
            action: () => {
              // Resetting both calculator and visualization data
              setResetCalculatorData(true);
              setResetVisualizationsData(true);
              setRefreshLabourProgressDataFromStorage(true);

              // Closing the Reset data modal window
              setOpenResetDataModalWindow(false);
            },
            text: "Ja, slett data",
            variant: "critical",
          },
        ]}
      >
        Dette sletter data både for visualisering og tidspunkt for langsom
        fremgang.
      </ModalDialog>
    </>
  );
};

export default NewBirth;
