import { ModalDialog, Size } from "@laerdal/life-react-components";

interface Props {
  isWindowOpen: boolean;
  setIsWindowOpen: (newValue: boolean) => void;
}

// The InfoBoxModal component renders a modal dialog box containing information about LabourView.
// It manages the state of the modal, determining whether it is open or closed.
const InfoBoxModal = ({ isWindowOpen, setIsWindowOpen }: Props) => {
  // Functions

  // Function to close the register data modal window
  const CloseInfoBoxModal = () => {
    // Indicate modal closure by setting openWindow flag to false
    isWindowOpen = false;
    // Update state to close the modal window
    setIsWindowOpen(false);
  };

  return (
    <ModalDialog
      key="InfoBoxModalDialog"
      title="Om LabourView"
      size={Size.Medium}
      isModalOpen={isWindowOpen}
      closeAction={CloseInfoBoxModal}
      shouldCloseOnOverlayClick={false}
      submitAction={() => {}}
    >
      <span className="info-box-modal-body">
        <p>
          LabourView har grafikk for visning/demonstrasjon av fremgang i fødsel,
          og viser tidspunkt for langsom fremgang slik det er beskrevet i WHO’s
          Labour Care Guide.
        </p>
        <p>
          LabourView er utviklet av Laerdal Medical i samarbeid med
          NORWELCG-forskningsgruppen ved Sykehuset Østfold og fødeavdelingen ved
          Nordlandssykehuset Bodø.
        </p>
        <p>
          LabourView er utviklet for bruk ved fødeavdelinger som er inkludert i
          NORWELCG-studien.
        </p>
      </span>
    </ModalDialog>
  );
};

export default InfoBoxModal;
