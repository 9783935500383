import ActionBar from "../shared/components/ActionBar";
import NewBirth from "../shared/components/NewBirth";
import Visualizations from "../shared/components/Visualizations";

const ProgressView = () => {
  return (
    <div className="main-container">
      <Visualizations />
      <ActionBar />
      <NewBirth />
    </div>
  );
};

export default ProgressView;
