import "../../resources/styles/WarningValues.css";

// Component that displays warning values for slow labor progress
// Used to show time thresholds for different cervical dilation stages
const WarningValues = () => {
  return (
    <div className="warning-values-main-container ">
      <div className="warning-values-inner-container">
        {/* Title for the warning values section */}
        <span className="warning-title">
          Definisjon av langsom fremgang:
        </span>

        {/* First stage of labor warnings */}
        <div className="stage-section">
          <span className="stage-title">Første stadium</span>
          <ul>
            {/* List of cervical dilation thresholds and their corresponding time limits */}
            <li>Mormunnsåpning 5 cm ≥6 timer</li>
            <li>Mormunnsåpning 6 cm ≥5 timer</li>
            <li>Mormunnsåpning 7 cm ≥3 timer</li>
            <li>Mormunnsåpning 8 cm ≥2.5 timer</li>
            <li>Mormunnsåpning 9 cm ≥2 timer</li>
          </ul>
        </div>

        {/* Second stage of labor warnings */}
        <div className="stage-section">
          <span className="stage-title">Andre stadium</span>
          <ul>
            {/* Time limits for nulliparous (Para 0) and multiparous (Para +) women */}
            <li>Para 0 ≥3 timer (trykketid ≥ 60 minutter)</li>
            <li>Para + ≥2 timer (trykketid ≥ 60 minutter)</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default WarningValues;
