import React, { useCallback, useEffect, useState, useMemo } from "react";
import {
  LocalStorageKey,
  refreshLabourDataFromLocalStorage,
  useLocalStorage,
} from "../helpers/LocalStorage";
import { ToastMessageType, Visibility } from "../helpers/Enums";
import { LabourProgressDto } from "../dtos/LabourProgressDto";
import { COLORS, SystemIcons, useToast } from "@laerdal/life-react-components";
import Global from "../helpers/Global";
import useLabourViewStore from "../hooks/useLabourViewStore";

// The progress player component visualizes the progression of labour assessments over time on a horizontal timeline.
const ProgressPlayer = () => {
  // Local storage data for labour progress
  const [labourProgressData, setLabourProgressData] = useLocalStorage(
    LocalStorageKey.LabourProgressDataArray,
    []
  );

  // Global state managers
  const {
    // Flag to trigger data refresh from local storage
    refreshLabourProgressDataFromStorage,
    // Function to set the refresh flag
    setRefreshLabourProgressDataFromStorage,
    // Updates the baby's body position in the global state
    setBabyBodyPosition,
    // Updates the baby's head position in the global state
    setBabyHeadPosition,
    // Updates the baby's descent level in the global state
    setBabyDescentLevel,
    // Updates the cervix opening level in the global state
    setCervixOpening,
    // Updates the baby's body rotation in the global state
    setBabyBodyRotation,
    // Indicates if the player mode is currently active
    isPlayerModeActive,
    // Function to set the player mode active state
    setIsPlayerModeActive,
  } = useLabourViewStore((state) => ({
    refreshLabourProgressDataFromStorage:
      state.refreshLabourProgressDataFromStorage,
    setRefreshLabourProgressDataFromStorage:
      state.setRefreshLabourProgressDataFromStorage,
    setBabyBodyPosition: state.setBabyBodyPosition,
    setBabyHeadPosition: state.setBabyHeadPosition,
    setBabyDescentLevel: state.setBabyDescentLevel,
    setCervixOpening: state.setCervixOpening,
    setBabyBodyRotation: state.setBabyBodyRotation,
    isPlayerModeActive: state.isPlayerModeActive,
    setIsPlayerModeActive: state.setIsPlayerModeActive,
  }));

  // Width of the timeline bar
  const progressBarWidth = 540;
  // Starting X-axis position of the timeline bar
  const initialTimelineBarPosX = 56;
  // Extra pixel buffer for decimal precision calculations
  const extraBufferDueToPxCalculation = 2;
  // Radius of the progress circle
  const circleRadius = 8;
  // Visibility of the progress circle based on data availability
  const progressCircleVisibility =
    labourProgressData.length > 0 ? Visibility.Visible : Visibility.Hidden;
  // Current X-axis position of the progress circle
  const [currentProgressCirclePosX, setCurrentProgressCirclePosX] = useState(
    initialTimelineBarPosX
  );
  // Width of the current timeline progress line
  let [currentTimelineProgressWidth, setCurrentTimelineProgressWidth] =
    useState(labourProgressData.length > 1 ? progressBarWidth : 0);
  // Controls visibility of play/pause button
  const [showPlayButton, setShowPlayButton] = useState(true);
  // Tracks the current observation point in the data array
  const [currentObservationPoint, setCurrentObservationPoint] = useState(0);
  // Indicates if the player is currently in progress
  const [isPlayerInProgress, setIsPlayerInProgress] = useState(false);
  // Interval for refreshing player data (in milliseconds)
  const playerRefreshInterval = 3000;

  // Toast notification hook
  const { addToast } = useToast();

  // Updates visualization components with specific labour progress data
  const setAssessmentValuesInVisualizations = useCallback(
    (item: LabourProgressDto) => {
      // Setting baby body position
      setBabyBodyPosition(item.babyBodyPosition);
      // Setting body rotation
      setBabyBodyRotation(item.babyBodyRotation);
      // Setting baby head position
      setBabyHeadPosition(item.babyHeadPosition);
      // Setting baby descent level
      setBabyDescentLevel(Number(item.babyBodyDescentLevel));
      // Setting cervix opening
      setCervixOpening(item.cervixOpeningLevel);
    },
    [
      setBabyBodyPosition,
      setBabyBodyRotation,
      setBabyDescentLevel,
      setBabyHeadPosition,
      setCervixOpening,
    ]
  );

  // Calculates position of an item on the timeline
  const calculateItemPosition = useMemo(() => {
    return (index: number) => {
      if (labourProgressData.length <= 1) return 0;
      const stepSize = progressBarWidth / (labourProgressData.length - 1);
      return index * stepSize;
    };
  }, [labourProgressData.length, progressBarWidth]);

  // Processes and memoizes labour progress data for efficient rendering
  const processedLabourProgressData = useMemo(() => {
    return labourProgressData.map((item: LabourProgressDto, index: number) => {
      const itemPositionX = calculateItemPosition(index);
      return {
        ...item,
        progressHandleBarPosX: itemPositionX,
        isLast: index === labourProgressData.length - 1,
        assessmentPosX: itemPositionX,
        isCurrent: index === currentObservationPoint,
      };
    });
  }, [labourProgressData, calculateItemPosition, currentObservationPoint]);

  // Updates circle indicator position and timeline progress based on current state
  const positionAssesmentInProgressBar = useCallback(
    (index: number) => {
      if (index < processedLabourProgressData.length) {
        const item = processedLabourProgressData[index];
        // Adjusting the circle position
        setCurrentProgressCirclePosX(
          initialTimelineBarPosX + (item.assessmentPosX || 0)
        );
        // Adjusting the progress line width
        setCurrentTimelineProgressWidth(item.assessmentPosX || 0);
        // Updating the visualization components with data from a specific labor progress object
        setAssessmentValuesInVisualizations(item);
      }
    },
    [
      processedLabourProgressData,
      setAssessmentValuesInVisualizations,
      initialTimelineBarPosX,
    ]
  );

  // Activates or deactivates the progress player
  const activateProgressPlayer = (activate: boolean) => {
    setIsPlayerInProgress(activate);
    setShowPlayButton(!activate);
    setIsPlayerModeActive(true);
  };

  // Effect for managing player progression
  useEffect(() => {
    let interval: NodeJS.Timer | undefined;
    if (isPlayerInProgress) {
      interval = setInterval(
        () => {
          // Check if currentObservationPoint is within the array bounds
          if (currentObservationPoint < processedLabourProgressData.length) {
            positionAssesmentInProgressBar(currentObservationPoint);
            setCurrentObservationPoint(currentObservationPoint + 1);
          } else {
            // If we've reached the end, stop the player
            setIsPlayerInProgress(false);
            setShowPlayButton(true);
            setIsPlayerModeActive(false);
            setCurrentObservationPoint(0);
            clearInterval(interval);
          }
        },
        currentObservationPoint === 0 ? 0 : playerRefreshInterval
      );
    } else {
      setIsPlayerInProgress(false);
      clearInterval(interval);
    }

    return () => {
      clearInterval(interval);
    };
  }, [
    currentObservationPoint,
    isPlayerInProgress,
    positionAssesmentInProgressBar,
    processedLabourProgressData,
    setIsPlayerModeActive,
  ]);

  // Effect for refreshing labour progress data from local storage
  useEffect(() => {
    if (refreshLabourProgressDataFromStorage) {
      // Retrieve updated data array from local storage
      const refreshedDataArray = refreshLabourDataFromLocalStorage();
      // Update state with refreshed data
      setLabourProgressData(refreshedDataArray);
      // Reset refresh flag after data retrieval
      setRefreshLabourProgressDataFromStorage(false);
      // Adjust timeline progress line width based on array length
      setCurrentTimelineProgressWidth(
        refreshedDataArray.length > 1 ? progressBarWidth : 0
      );
      // Set position of progress circle on timeline based on array length and initial position
      setCurrentProgressCirclePosX(
        initialTimelineBarPosX +
          (refreshedDataArray.length > 1
            ? progressBarWidth - extraBufferDueToPxCalculation
            : 0)
      );
    }
  }, [
    currentTimelineProgressWidth,
    initialTimelineBarPosX,
    refreshLabourProgressDataFromStorage,
    setLabourProgressData,
    setRefreshLabourProgressDataFromStorage,
  ]);

  return (
    <div
      className={`progress-player-container ${
        isPlayerModeActive ? "z-index-to-top" : ""
      }`}
    >
      <svg
        width="630"
        height="58"
        viewBox="0 0 630 58"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {/* Play/Pause icons */}
        <g
          style={{
            cursor: "pointer",
          }}
          onClick={() => {
            if (labourProgressData.length > 0) {
              activateProgressPlayer(showPlayButton);
            } else {
              return Global.showMessageBox(
                addToast,
                "Ingen data er registrert",
                ToastMessageType.Warning
              );
            }
          }}
          width={40}
          height={40}
        >
          {showPlayButton && (
            <g transform={`translate(9, 22)`}>
              <SystemIcons.Play color={COLORS.neutral_600}></SystemIcons.Play>
            </g>
          )}
          {!showPlayButton && (
            <g transform={`translate(12, 22)`}>
              <SystemIcons.Pause color={COLORS.neutral_600}></SystemIcons.Pause>
            </g>
          )}
          <rect
            transform={`translate(7, 14)`}
            width={40}
            height={40}
            fill="transparent"
          ></rect>
        </g>

        {/* Starting limit line on the timeline */}
        <rect
          x={initialTimelineBarPosX - 1} // -1 to make it align with the vertical line
          y="34"
          width="2"
          height="8"
          rx="1"
          fill="#949494"
          visibility={
            labourProgressData.length > 0
              ? Visibility.Visible
              : Visibility.Hidden
          }
        />

        {/* Final limit line on the timeline */}
        <rect
          x={initialTimelineBarPosX + progressBarWidth - 1} // -1 to make it align with the vertical line
          y="34"
          width="2"
          height="8"
          rx="1"
          fill="#949494"
          visibility={
            labourProgressData.length > 0
              ? Visibility.Visible
              : Visibility.Hidden
          }
        />

        {/* Main line in timeline */}
        <rect
          x={initialTimelineBarPosX}
          y="31"
          width={progressBarWidth}
          height="6"
          rx="3"
          fill="#E5E5E5"
        />

        {/* Progress line on top of the timeline */}
        <rect
          x={initialTimelineBarPosX}
          y="31"
          width={currentTimelineProgressWidth}
          height="6"
          rx="3"
          fill="#2E7FA1"
        />

        {/* Drawing vertical steps and current time of progress in timeline */}
        <>
          {processedLabourProgressData.map(
            (item: LabourProgressDto, index: number) => (
              <React.Fragment key={index}>
                {/* Current time of progress in timeline */}
                <text
                  fill="#666"
                  fontFamily="Lato"
                  fontSize="12"
                  fontStyle="normal"
                  fontWeight={
                    index === currentObservationPoint - 1 ||
                    (item.isLast &&
                      currentObservationPoint ===
                        processedLabourProgressData.length)
                      ? 900
                      : 400
                  }
                  pointerEvents="none"
                  textAnchor="middle"
                  x={initialTimelineBarPosX + (item.progressHandleBarPosX || 0)}
                  y="15"
                  style={{ userSelect: "none" }}
                >
                  <tspan>{item.id}</tspan>
                </text>

                {/* Small vertical line indicating progress step in timeline */}
                <rect
                  x={
                    initialTimelineBarPosX +
                    (item.progressHandleBarPosX || 0) -
                    1
                  }
                  y="20"
                  width="2"
                  height="8"
                  rx="1"
                  fill="#2E7FA1"
                />
              </React.Fragment>
            )
          )}
        </>

        {/* Circle on top of timeline indicating current progress time */}
        <circle
          visibility={progressCircleVisibility}
          cx={currentProgressCirclePosX}
          cy="34"
          r={circleRadius}
          fill="#276D8B"
        />
      </svg>
    </div>
  );
};

export default ProgressPlayer;
