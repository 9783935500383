import { useEffect, useState } from "react";

// Helper function to get the current orientation
const getOrientation = () => window.screen.orientation.type;

// Custom hook to track and update screen orientation
// Returns the current orientation and updates when it changes
const useScreenOrientation = () => {
  const [orientation, setOrientation] = useState(getOrientation());

  useEffect(() => {
    const updateOrientation = () => {
      setOrientation(getOrientation());
    };

    window.addEventListener("orientationchange", updateOrientation);
    return () => {
      window.removeEventListener("orientationchange", updateOrientation);
    };
  }, []);

  return orientation;
};

export default useScreenOrientation;
