import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { ToastMessageType } from "./Enums";

// Provides functions to validate and manage time and cervix opening data for labor progress tracking. It uses `dayjs` library
// for time format checking and calculations, ensuring valid inputs and projecting future times based on cervix opening.
export default class SlowProgressCalculation {
  // Checks if the provided time string is in the "HH:mm" 24-hour format.
  static checkTimeFormat = (timeValue: string) => {
    const re = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
    return timeValue && re.test(timeValue);
  };

  static validateInputsBeforeCalculation = (
    selectedCervixOpening: string,
    registeredCervixOpening: string,
    selectedLatestTime: string,
    registeredLatestTime: string
  ) => {
    // Enabling use of custom parse format in dayjs
    dayjs.extend(customParseFormat);

    // Parsed variables
    let selectedLatestTimeAsDayJSObject = dayjs(selectedLatestTime); 
    const registeredLatestTimeAsDayJSObject = dayjs(
      registeredLatestTime,
      "HH:mm"
    );
    const parsedSelectedCervixOpening = parseInt(selectedCervixOpening);
    const parsedRegisteredCervixOpening = parseInt(registeredCervixOpening);

    // Returns a warning message if the cervix opening value is empty.
    if (selectedCervixOpening === "") {
      return {
        message: "Legg til mormunnsåpning",
        messageType: ToastMessageType.Warning,
      };
    }

    // Returns a warning message if the selected latest time value is empty.
    if (selectedLatestTime === "") {
      return {
        message: "Legg til tidspunkt for måling",
        messageType: ToastMessageType.Warning,
      };
    }

    // Checking timestamps (Earlier / Same / Later - new)

    // Earlier timestamp
    if (
      selectedLatestTimeAsDayJSObject.isBefore(
        registeredLatestTimeAsDayJSObject,
        "minute"
      )
    ) {
      // Smaller opening
      if (parsedSelectedCervixOpening < parsedRegisteredCervixOpening) {
        return {
          message:
            "Kombinasjonen av mormunnsåpning og tidspunkt endrer ikke tidspunkt for for langsom fremgang",
          messageType: ToastMessageType.Info,
        };
      } else if (parsedSelectedCervixOpening > parsedRegisteredCervixOpening) {
        // Bigger opening
        // This message is not displayed anywhere since the confirmation window will have its own message.
        // It is used internally to identify the checked scenario.
        return {
          message: "Earlier timestamp / Bigger opening",
          messageType: "tidspunkt-confirmation",
        };
      }
    }

    // Same timestamp
    if (
      selectedLatestTimeAsDayJSObject.isSame(
        registeredLatestTimeAsDayJSObject,
        "minute"
      )
    ) {
      // Same opening
      if (parsedSelectedCervixOpening === parsedRegisteredCervixOpening) {
        return {
          message:
            "Kombinasjonen av mormunnsåpning og tidspunkt endrer ikke tidspunkt for for langsom fremgang",
          messageType: ToastMessageType.Info,
        };
      }
    }

    // Later timestamp (new)
    if (
      selectedLatestTimeAsDayJSObject.isAfter(
        registeredLatestTimeAsDayJSObject,
        "minute"
      )
    ) {
      // Smaller opening
      if (parsedSelectedCervixOpening < parsedRegisteredCervixOpening) {
        // This message is not displayed anywhere since the confirmation window will have its own message.
        // It is used internally to identify the checked scenario.
        return {
          message: "Later timestamp / Smaller opening",
          messageType: "tidspunkt-confirmation",
        };
      } else if (
        parsedSelectedCervixOpening === parsedRegisteredCervixOpening
      ) {
        // Same opening
        return {
          message:
            "Kombinasjonen av mormunnsåpning og tidspunkt endrer ikke tidspunkt for for langsom fremgang",
          messageType: ToastMessageType.Info,
        };
      }
    }
  };

  // Calculates and returns the current slow progress value based on the current cervix opening and time.
  static calculateCurrentSlowProgressValue = (
    cervixOpening: string,
    registrationCurrentTime: string
  ) => {
    // Enabling use of custom parse format in dayjs
    dayjs.extend(customParseFormat);

    let addedHours = 0;
    const currentRegistrationTime = dayjs(registrationCurrentTime);

    switch (cervixOpening) {
      case "5":
        addedHours = 6;
        break;
      case "6":
        addedHours = 5;
        break;
      case "7":
        addedHours = 3;
        break;
      case "8":
        addedHours = 2.5;
        break;
      case "9":
        addedHours = 2;
        break;
    }

    return [
      currentRegistrationTime.add(addedHours, "hour").format("HH:mm"),
      addedHours,
    ];
  };

  // Checks if a given new time is earlier than a registered time in the system.
  static checkIfTimeEarlierThanRegistered = (
    registeredTime: string,
    newTime: string
  ) => {
    // Enabling use of custom parse format in dayjs
    dayjs.extend(customParseFormat);

    const parsedRegisteredTime = dayjs(registeredTime, "HH:mm");
    const parsedNewTime = dayjs(newTime, "HH:mm");

    return parsedNewTime.isBefore(parsedRegisteredTime);
  };
}
