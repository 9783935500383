import {
  BabyBodyPositions,
  BabyHeadPositions,
  BreechPositions,
  CephalicPositions,
} from "./Enums";

export default class DropDownDatasources {
  static getDescentPosition = () => {
    return [
      {
        value: BabyBodyPositions.Cephalic,
        displayLabel: BabyBodyPositions.Cephalic,
      },
      {
        value: BabyBodyPositions.Breech,
        displayLabel: BabyBodyPositions.Breech,
      },
    ];
  };

  static getCervixOpeningValues = (include10CmsValue: boolean = true) => {
    var cervixValues = [
      {
        value: "5",
        displayLabel: "5 cm",
      },
      {
        value: "6",
        displayLabel: "6 cm",
      },
      {
        value: "7",
        displayLabel: "7 cm",
      },
      {
        value: "8",
        displayLabel: "8 cm",
      },
      {
        value: "9",
        displayLabel: "9 cm",
      },
    ];

    if (include10CmsValue)
      cervixValues.push({
        value: "10",
        displayLabel: "10 cm",
      });

    return cervixValues;
  };

  static getBabyDescentLevelValues = () => {
    return [
      {
        value: "-5",
        displayLabel: "-5",
      },
      {
        value: "-4",
        displayLabel: "-4",
      },
      {
        value: "-3",
        displayLabel: "-3",
      },
      {
        value: "-2",
        displayLabel: "-2",
      },
      {
        value: "-1",
        displayLabel: "-1",
      },
      {
        value: "0",
        displayLabel: "0",
      },
      {
        value: "1",
        displayLabel: "+1",
      },
      {
        value: "2",
        displayLabel: "+2",
      },
      {
        value: "3",
        displayLabel: "+3",
      },
      {
        value: "4",
        displayLabel: "+4",
      },
    ];
  };

  static getBabyRotationByCephalicPositionValues = () => {
    return [
      {
        value: CephalicPositions.OcciputAnterior,
        displayLabel: CephalicPositions.OcciputAnterior + " (12)",
      },
      {
        value: CephalicPositions.LeftOcciputAnterior + "1",
        displayLabel: CephalicPositions.LeftOcciputAnterior + " (1)",
      },
      {
        value: CephalicPositions.LeftOcciputAnterior + "2",
        displayLabel: CephalicPositions.LeftOcciputAnterior + " (2)",
      },
      {
        value: CephalicPositions.LeftOcciputTransverse,
        displayLabel: CephalicPositions.LeftOcciputTransverse + " (3)",
      },
      {
        value: CephalicPositions.LeftOcciputPosterior + "4",
        displayLabel: CephalicPositions.LeftOcciputPosterior + " (4)",
      },
      {
        value: CephalicPositions.LeftOcciputPosterior + "5",
        displayLabel: CephalicPositions.LeftOcciputPosterior + " (5)",
      },
      {
        value: CephalicPositions.OcciputPosterior,
        displayLabel: CephalicPositions.OcciputPosterior + " (6)",
      },
      {
        value: CephalicPositions.RightOcciputPosterior + "7",
        displayLabel: CephalicPositions.RightOcciputPosterior + " (7)",
      },
      {
        value: CephalicPositions.RightOcciputPosterior + "8",
        displayLabel: CephalicPositions.RightOcciputPosterior + " (8)",
      },
      {
        value: CephalicPositions.RightOcciputTransverse,
        displayLabel: CephalicPositions.RightOcciputTransverse + " (9)",
      },
      {
        value: CephalicPositions.RightOcciputAnterior + "10",
        displayLabel: CephalicPositions.RightOcciputAnterior + " (10)",
      },
      {
        value: CephalicPositions.RightOcciputAnterior + "11",
        displayLabel: CephalicPositions.RightOcciputAnterior + " (11)",
      },
    ];
  };

  static getBabyRotationByBreechPositionValues = () => {
    return [
      {
        value: BreechPositions.SacrumAnterior,
        displayLabel: BreechPositions.SacrumAnterior + " (12)",
      },
      {
        value: BreechPositions.LeftSacrumAnterior + "1",
        displayLabel: BreechPositions.LeftSacrumAnterior + " (1)",
      },
      {
        value: BreechPositions.LeftSacrumAnterior + "2",
        displayLabel: BreechPositions.LeftSacrumAnterior + " (2)",
      },
      {
        value: BreechPositions.LeftSacrumTransverse,
        displayLabel: BreechPositions.LeftSacrumTransverse + " (3)",
      },
      {
        value: BreechPositions.LeftSacrumPosterior + "4",
        displayLabel: BreechPositions.LeftSacrumPosterior + " (4)",
      },
      {
        value: BreechPositions.LeftSacrumPosterior + "5",
        displayLabel: BreechPositions.LeftSacrumPosterior + " (5)",
      },
      {
        value: BreechPositions.SacrumPosterior,
        displayLabel: BreechPositions.SacrumPosterior + " (6)",
      },
      {
        value: BreechPositions.RightSacrumPosterior + "7",
        displayLabel: BreechPositions.RightSacrumPosterior + " (7)",
      },
      {
        value: BreechPositions.RightSacrumPosterior + "8",
        displayLabel: BreechPositions.RightSacrumPosterior + " (8)",
      },
      {
        value: BreechPositions.RightSacrumTransverse,
        displayLabel: BreechPositions.RightSacrumTransverse + " (9)",
      },
      {
        value: BreechPositions.RightSacrumAnterior + "10",
        displayLabel: BreechPositions.RightSacrumAnterior + " (10)",
      },
      {
        value: BreechPositions.RightSacrumAnterior + "11",
        displayLabel: BreechPositions.RightSacrumAnterior + " (11)",
      },
    ];
  };

  static getAsyncliticPositionValues = () => {
    return [
      {
        value: BabyHeadPositions.Centered,
        displayLabel: "Sentrert",
        disabled: false
      },
      {
        value: BabyHeadPositions.FrontAsynclitic,
        displayLabel: "Fremre asynklitisme",
        disabled: true  // Initially disabled since default is centered
      },
      {
        value: BabyHeadPositions.BackAsynclitic,
        displayLabel: "Bakre asynklitisme",
        disabled: true, // Initially disabled since default is centered
      },
    ];
  };
}
