import dayjs from "dayjs";
import { DatePickerDateType } from "./Enums";
import { PickerValue } from "react-mobile-picker";

// Extends PickerValue to define a date picker object with specific properties.
export interface DatePickerObject extends PickerValue {
  // The day selected in the date picker (e.g., "I går" or "I dag").
  day: DatePickerDateType;
  // The hour selected, formatted as a two-digit string (e.g., "09").
  hour: string;
  // The minute selected, formatted as a two-digit string (e.g., "30").
  minute: string;
}

export default class DateTimePickerManipulation {
  // Generates an array of strings representing numbers from 0 to (length - 1).
  // Optionally pads numbers with leading zeros if 'pad' is true.
  static generateNumberArray = (
    length: number,
    pad: boolean = false
  ): string[] => {
    return Array.from({ length }, (_, index) =>
      pad ? index.toString().padStart(2, "0") : index.toString()
    );
  };

  // Returns an array of possible date values for the picker (e.g., "I går" and "I dag").
  static getDateValues = () => {
    return [DatePickerDateType.Igar, DatePickerDateType.Idag];
  };

  // Formats a number to a two-digit string, padding with a leading zero if necessary.
  static formatHoursMinutes = (hourOrMinute: number): string => {
    return hourOrMinute.toString().padStart(2, "0");
  };

  // Converts a picker value into a Day.js object.
  // Adjusts the date based on whether the day is "today" or the previous day.
  static transformPickerValueIntoDayJSObject = (pickerValue: any) => {
    const timeValue = `${pickerValue.hour}:${pickerValue.minute}`;
    return pickerValue.day === DatePickerDateType.Idag
      ? dayjs(timeValue, "HH:mm")
      : dayjs(timeValue, "HH:mm").subtract(1, "day");
  };

  // Returns the current time formatted as a DatePickerObject.
  static getCurrentTimeDateTimePickerValue = (): DatePickerObject => {
    return {
      day: DatePickerDateType.Idag,
      hour: this.formatHoursMinutes(dayjs().hour()),
      minute: this.formatHoursMinutes(dayjs().minute()),
    };
  };
}
