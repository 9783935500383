import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { HashRouter } from "react-router-dom";
import { StyleSheetManager } from "styled-components";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    {/* 
        Using HashRouter instead of BrowserRouter to simplify deployment on static hosting 
        where server routing is not configurable. 
        This prevents 404 errors on refresh or direct URL access, as the hash portion 
        of the URL is handled client-side, ensuring a smooth user experience.
      */}
    <HashRouter>
      {/* Adding the StyleSheetManager component to the root of the application 
          to avoid warnings on styled-components unknow props */}
      <StyleSheetManager shouldForwardProp={() => true}>
        <App />
      </StyleSheetManager>
    </HashRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
