import {
  SegmentControl,
  SegmentControlItem,
} from "@laerdal/life-react-components";
import { useEffect, useState } from "react";
import { To, useNavigate } from "react-router-dom";

// Extending SegmentControlItem with additional navigation property 'to'
export interface TabItem extends SegmentControlItem {
  // Specifies the target route for the tab
  to: To;
}

// Props for the TabGroup component
interface TabGroupProps {
  // Array of tab items
  children: TabItem[];
  // Initially selected tab key
  initialSelected: string;
}

// Renders a segmented control for tab navigation and handles route changes based on selected tab
function TabGroup({ children, initialSelected }: TabGroupProps) {
  // Hook to navigate programmatically
  const navigate = useNavigate();
  // State for the currently selected tab key
  const [selectedKey, setSelectedKey] = useState(initialSelected);

  // Effect to handle navigation whenever the selected tab changes
  useEffect(() => {
    // Find the selected tab by its key
    const selectedTab = children.find((item) => item.key === selectedKey);
    // If the tab exists and has a route, navigate to it
    if (selectedTab?.to) {
      navigate(selectedTab.to);
    }
  }, [children, navigate, selectedKey]);

  return (
    <SegmentControl
      items={children}
      onChange={(value) => setSelectedKey(value)}
      selected={selectedKey}
    />
  );
}

export default TabGroup;
