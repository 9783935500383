import React, { useEffect, useCallback, useMemo } from "react";
import {
  ModalDialog,
  Size,
  SystemIcons,
  Table,
  TableColumn,
} from "@laerdal/life-react-components";
import { LabourProgressDto } from "../../dtos/LabourProgressDto";
import {
  LocalStorageKey,
  refreshLabourDataFromLocalStorage,
  useLocalStorage,
} from "../../helpers/LocalStorage";
import DropDownDatasources from "../../helpers/DropDownDatasources";
import useLabourViewStore from "../../hooks/useLabourViewStore";
import "../../../resources/styles/ViewDataModal.css";

// Props for the ViewDataModal component
interface ViewDataModalProps {
  // Flag indicating whether the modal window is open
  isWindowOpen: boolean;
  // Function to set the open/closed state of the modal window
  setIsWindowOpen: (newValue: boolean) => void;
}

// Interface defining the structure of a row in the data table
interface DataTableRow {
  // Unique identifier for each row
  id: number;
  // Baby's descent level during labour
  descentLevel: string;
  // Cervix opening measurement during labour
  cervixOpening: string;
  // Baby's head rotation position during labour
  headRotationPosition: string;
  // Baby's body position during labour
  babyBodyPosition: string;
}

// Calculate baby rotation position values once
const babyRotationPositionValues =
  DropDownDatasources.getBabyRotationByCephalicPositionValues().concat(
    DropDownDatasources.getBabyRotationByBreechPositionValues()
  );

// ViewDataModal displays and manages labour progress data.
// It renders a table of entries stored in local storage,
// allowing users to view, delete, and reorder rows.
// The component updates dynamically based on global state changes.
const ViewDataModal = ({
  isWindowOpen,
  setIsWindowOpen,
}: ViewDataModalProps) => {
  // Global state managers
  const {
    refreshLabourProgressDataFromStorage,
    setRefreshLabourProgressDataFromStorage,
  } = useLabourViewStore((state) => ({
    refreshLabourProgressDataFromStorage:
      state.refreshLabourProgressDataFromStorage,
    setRefreshLabourProgressDataFromStorage:
      state.setRefreshLabourProgressDataFromStorage,
  }));

  // Local storage state for labour progress data
  const [labourProgressData, setLabourProgressData] = useLocalStorage(
    LocalStorageKey.LabourProgressDataArray,
    []
  );

  // Function to close the register data modal window
  const closeRegisterDataModal = () => {
    setIsWindowOpen(false);
  };

  // Function to delete an item from the labour progress data list
  const deleteItemFromList = (id: number) => {
    const index = labourProgressData.findIndex(
      (item: LabourProgressDto) => item.id === id
    );
    if (index !== -1) {
      const newData = labourProgressData.filter(
        (item: LabourProgressDto) => item.id !== id
      );

      // Update IDs for all items after the deleted item
      for (let i = index; i < newData.length; i++) {
        newData[i].id = i + 1;
      }

      setLabourProgressData(newData);
      setRefreshLabourProgressDataFromStorage(true);
    }
  };

  // Helper function to swap rows and update IDs
  const swapRowsAndUpdateIds = useCallback(
    (index1: number, index2: number) => {
      // Create a new array to avoid mutating the original state
      const newData = [...labourProgressData];
      // Swap the positions of the two rows using array destructuring
      [newData[index1], newData[index2]] = [newData[index2], newData[index1]];
      // Update IDs for all items to ensure consecutive numbering
      newData.forEach((item, idx) => {
        item.id = idx + 1;
      });
      // Update the state with the new data
      setLabourProgressData(newData);
      // Trigger a refresh of the labour progress data in the global state
      setRefreshLabourProgressDataFromStorage(true);
    },
    [
      labourProgressData,
      setLabourProgressData,
      setRefreshLabourProgressDataFromStorage,
    ]
  );

  // Move a row up in the list, if it's not already at the top
  const moveRowUp = (index: number) => {
    if (index > 0) swapRowsAndUpdateIds(index - 1, index);
  };

  // Move a row down in the list, if it's not already at the bottom
  const moveRowDown = (index: number) => {
    if (index < labourProgressData.length - 1)
      swapRowsAndUpdateIds(index, index + 1);
  };

  // Helper function to create icon column definition
  const createIconColumn = (
    key: string,
    icon: React.ReactNode,
    action: (row: DataTableRow) => void
  ): TableColumn => ({
    key,
    name: "",
    type: "icon",
    sortable: false,
    icon,
    action,
    width: 50,
    justify: "center",
  });

  // Function to get the column definitions for the data table
  const getDataTableColumns = (): TableColumn[] => {
    return [
      // Move row up icon
      createIconColumn("arrowUpIcon", <SystemIcons.ArrowLineUp />, (row) => {
        if (row.id !== 1) moveRowUp(row.id - 1);
      }),
      // Move row down icon
      createIconColumn(
        "arrowDownIcon",
        <SystemIcons.ArrowLineDown />,
        (row) => {
          if (row.id !== labourProgressData.length) moveRowDown(row.id - 1);
        }
      ),
      // Row ID column
      {
        key: "id",
        name: "Id",
        type: "text",
        sortable: true,
        width: 50,
      },
      // Baby body position column
      {
        key: "babyBodyPosition",
        name: "Presentasjon",
        type: "text",
      },
      // Baby body asynclism column
      {
        key: "babyHeadPosition",
        name: "Asynklitisme",
        type: "text",
      },
      // Descent level column
      {
        key: "descentLevel",
        name: "Nivå",
        type: "number",
      },
      // Cervix opening column
      {
        key: "cervixOpening",
        name: "Mormunnsåpning",
        type: "text",
      },
      // Head rotation position column
      {
        key: "headRotationPosition",
        name: "Posisjon",
        type: "text",
      },
      // Delete row icon
      createIconColumn("trashIcon", <SystemIcons.Delete />, (row) => {
        deleteItemFromList(row.id);
      }),
    ];
  };

  // Memoized function to get the rows for the data table
  const getDataTableRows = useMemo(() => {
    return () =>
      labourProgressData.map((item: LabourProgressDto) => ({
        id: item.id,
        babyBodyPosition: item.babyBodyPosition,
        babyHeadPosition: item.babyHeadPosition,
        descentLevel: item.babyBodyDescentLevel,
        cervixOpening: `${item.cervixOpeningLevel} cm`,
        headRotationPosition:
          babyRotationPositionValues.find(
            (t) => t.value === item.babyBodyRotation
          )?.displayLabel || "",
      }));
  }, [labourProgressData]);

  // Effect to refresh labour progress data from local storage
  useEffect(() => {
    if (refreshLabourProgressDataFromStorage) {
      setLabourProgressData(refreshLabourDataFromLocalStorage());
      setRefreshLabourProgressDataFromStorage(false);
    }
  }, [
    refreshLabourProgressDataFromStorage,
    setLabourProgressData,
    setRefreshLabourProgressDataFromStorage,
  ]);

  return (
    <ModalDialog
      key="RegisterDataModalDialog"
      title="Registrerte data"
      size={Size.Large}
      width="850px"
      isModalOpen={isWindowOpen}
      closeAction={closeRegisterDataModal}
      shouldCloseOnOverlayClick={false}
      submitAction={() => {}}
    >
      <div className="view-data-container">
        <Table
          columns={getDataTableColumns()}
          border={true}
          rows={getDataTableRows()}
          noRowsLabel="Ingen data funnet"
          // Accordion set to true hides table footer. Bottom border handled in CSS.
          accordion={true}
        />
      </div>
    </ModalDialog>
  );
};

export default ViewDataModal;
