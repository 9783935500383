export default class SVGManipulation {
  // Retrieves the bounding rectangle coordinates of an SVG element.
  static getSvgElementCoordinates(svgElement: SVGSVGElement) {
    return svgElement ? svgElement.getBoundingClientRect() : null;
  }

  // Converts SVG coordinates to pixel coordinates based on the element's bounding rectangle and viewBox.
  static svgLocationToPixel = (
    svgElement: SVGSVGElement,
    svgX: number,
    svgY: number
  ) => {
    const svgRect = svgElement.getBoundingClientRect();
    const viewBox = svgElement.viewBox.baseVal;

    // Calculate the scaling factor between the viewBox and the actual SVG size
    const scaleX = svgRect.width / viewBox.width;
    const scaleY = svgRect.height / viewBox.height;

    // Calculate the pixel position
    const pixelX = svgX * scaleX + svgRect.left;
    const pixelY = svgY * scaleY + svgRect.top;

    return { x: pixelX, y: pixelY };
  };

  // Converts pixel coordinates to corresponding SVG coordinates.
  static pixelToSvgLocation = (
    svgElement: SVGSVGElement,
    pixelX: number,
    pixelY: number
  ) => {
    const svgRect = svgElement.getBoundingClientRect();
    const viewBox = svgElement.viewBox.baseVal;

    // Calculate the scaling factor between the viewBox and the actual SVG size
    const scaleX = svgRect.width / viewBox.width;
    const scaleY = svgRect.height / viewBox.height;

    // Calculate the SVG position
    const svgX = (pixelX - svgRect.left) / scaleX;
    const svgY = (pixelY - svgRect.top) / scaleY;

    return { x: svgX, y: svgY };
  };

  // Retrieves the pixel coordinates of an SVG element by its ID.
  static getSvgElementPxLocationById = (id: string) => {
    const svgElement = document.querySelector(id);
    const svgRect = svgElement!.getBoundingClientRect();

    return { x: svgRect.x, y: svgRect.y };
  };

  // Retrieves the bounding rectangle of an SVG element by its ID.
  static getSvgBoundingClientRectById = (id: string) => {
    const svgElement = document.querySelector(id);
    return svgElement!.getBoundingClientRect();
  };
}
