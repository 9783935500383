import React from "react";

import { ReactComponent as FiveCmCervix } from "../../resources/images/cervix-dilation/5cm.svg";
import { ReactComponent as SixCmCervix } from "../../resources/images/cervix-dilation/6cm.svg";
import { ReactComponent as SevenCmCervix } from "../../resources/images/cervix-dilation/7cm.svg";
import { ReactComponent as EightCmCervix } from "../../resources/images/cervix-dilation/8cm.svg";
import { ReactComponent as NineCmCervix } from "../../resources/images/cervix-dilation/9cm.svg";
import { ReactComponent as TenCmCervix } from "../../resources/images/cervix-dilation/10cm.svg";

interface Props {
  value: string;
}

const possibleCervixDilationValues: { [key: string]: React.ComponentType } = {
  "5": FiveCmCervix,
  "6": SixCmCervix,
  "7": SevenCmCervix,
  "8": EightCmCervix,
  "9": NineCmCervix,
  "10": TenCmCervix,
};

const CervixDilation = ({ value }: Props) => {
  const CervixDilationComponent = possibleCervixDilationValues[value];
  return <CervixDilationComponent />;
};

export default CervixDilation;
