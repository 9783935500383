// Here is posible to find an explanation about the labels and the positions
// https://motifmedical.com/blog/baby-position-birth-preparation
export enum CephalicPositions {
  OcciputAnterior = "OA",
  LeftOcciputAnterior = "LOA",
  LeftOcciputTransverse = "LOT",
  LeftOcciputPosterior = "LOP",
  OcciputPosterior = "OP",
  RightOcciputPosterior = "ROP",
  RightOcciputTransverse = "ROT",
  RightOcciputAnterior = "ROA",
}

// Here is posible to find an explanation about the labels and the positions
// https://motifmedical.com/blog/baby-position-birth-preparation
export enum BreechPositions {
  SacrumAnterior = "SA",
  LeftSacrumAnterior = "LSA",
  LeftSacrumTransverse = "LST",
  LeftSacrumPosterior = "LSP",
  SacrumPosterior = "SP",
  RightSacrumPosterior = "RSP",
  RightSacrumTransverse = "RST",
  RightSacrumAnterior = "RSA",
}

export enum BabyBodyPositions {
  Cephalic = "Hodeleie",
  Breech = "Sete-/fotleie",
}

export enum BabyHeadPositions {
  Centered = "Sentrert",
  FrontAsynclitic = "Fremre",
  BackAsynclitic = "Bakre",
  Breech = "Seteleie",
}

export enum Visibility {
  Visible = "visible",
  Hidden = "hidden",
}

export enum ToastMessageType {
  Info,
  Warning,
  Error,
  Success,
}

export enum DatePickerDateType {
  Igar = "I går",
  Idag = "I dag",
}
