import Calculator from "../shared/components/Calculator";
import NewBirth from "../shared/components/NewBirth";
import WarningValues from "../shared/components/WarningValues";

const CalculatorView = () => {
  return (
    <div className="main-container">
      <Calculator />
      <NewBirth />
      <WarningValues />
    </div>
  );
};

export default CalculatorView;
