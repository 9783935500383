import { useState } from "react";
import { Button, Size, useToast } from "@laerdal/life-react-components";
import ViewDataModal from "./modals/ViewDataModal";
import ProgressPlayer from "./ProgressPlayer";
import { useLabourProgressData } from "../hooks/useLabourProgressData";
import Global from "../helpers/Global";
import { ToastMessageType } from "../helpers/Enums";
import "../../resources/styles/ActionBar.css";

// Reusable button component for action bar items
const ActionButton = ({
  variant,
  onClick,
  children,
}: {
  variant: "primary" | "secondary" | "tertiary" | "positive" | "critical";
  onClick: () => void;
  children: React.ReactNode;
}) => (
  <div className="button-container">
    <Button
      variant={variant}
      size={Size.Medium}
      width="146px"
      onClick={onClick}
    >
      {children}
    </Button>
  </div>
);

// Main ActionBar component for managing labour progress data and navigation
const ActionBar = () => {
  // State for controlling the visibility of the ViewDataModal
  const [openViewDataModalWindow, setOpenViewDataModalWindow] = useState(false);
  // State for tracking the ID of the currently open toast
  const [currentOpenToastId, setCurrentOpenToastId] = useState("");

  // Custom hook for managing labour progress data
  const { registerData } = useLabourProgressData();

  // Hook for managing toast notifications
  const { addToast, removeToast } = useToast();

  // Handler for registering new labour progress data
  const handleDataRegistration = () => {
    removeToast(currentOpenToastId);
    registerData();
    const toastId = Global.showMessageBox(
      addToast,
      "Data registrert",
      ToastMessageType.Success,
      1500
    );
    setCurrentOpenToastId(toastId);
  };

  return (
    <>
      <div className="actionbar-container">
        <ActionButton variant="primary" onClick={handleDataRegistration}>
          Legg til data
        </ActionButton>
        <ProgressPlayer />
        <ActionButton
          variant="secondary"
          onClick={() => setOpenViewDataModalWindow(true)}
        >
          Vis data
        </ActionButton>
      </div>
      <ViewDataModal
        isWindowOpen={openViewDataModalWindow}
        setIsWindowOpen={setOpenViewDataModalWindow}
      />
    </>
  );
};

export default ActionBar;
