import { BreechPositions, CephalicPositions } from "./Enums";

export default class Dictionaries {
  // Here is posible to find an explanation about the labels and the positions
  // https://motifmedical.com/blog/baby-position-birth-preparation
  static rotationLabelsByAngleAndCephalicPosition: { [angle: number]: string } =
    {
      0: CephalicPositions.OcciputAnterior,
      30: CephalicPositions.LeftOcciputAnterior,
      60: CephalicPositions.LeftOcciputAnterior,
      90: CephalicPositions.LeftOcciputTransverse,
      120: CephalicPositions.LeftOcciputPosterior,
      150: CephalicPositions.LeftOcciputPosterior,
      180: CephalicPositions.OcciputPosterior,
      210: CephalicPositions.RightOcciputPosterior,
      240: CephalicPositions.RightOcciputPosterior,
      270: CephalicPositions.RightOcciputTransverse,
      300: CephalicPositions.RightOcciputAnterior,
      330: CephalicPositions.RightOcciputAnterior,
      360: CephalicPositions.OcciputAnterior,
    };

  // Here is posible to find an explanation about the labels and the positions
  // https://motifmedical.com/blog/baby-position-birth-preparation
  static rotationLabelsByAngleAndBreechPosition: { [angle: number]: string } = {
    0: BreechPositions.SacrumAnterior,
    30: BreechPositions.LeftSacrumAnterior,
    60: BreechPositions.LeftSacrumAnterior,
    90: BreechPositions.LeftSacrumTransverse,
    120: BreechPositions.LeftSacrumPosterior,
    150: BreechPositions.LeftSacrumPosterior,
    180: BreechPositions.SacrumPosterior,
    210: BreechPositions.RightSacrumPosterior,
    240: BreechPositions.RightSacrumPosterior,
    270: BreechPositions.RightSacrumTransverse,
    300: BreechPositions.RightSacrumAnterior,
    330: BreechPositions.RightSacrumAnterior,
    360: BreechPositions.SacrumAnterior,
  };

  // This dictionary is used to have the internal values of the rotation position by angle
  // These values are correlated to the SVG image names that represent each of those positions.
  // The number in some of them are because there are 2 positions for each type that have
  // a slightly difference in the SVG image that makes the rotation position unique but
  // within the same typing
  static rotationLabelInternalValuesByAngleAndCephalicPosition: {
    [angle: number]: string;
  } = {
    0: CephalicPositions.OcciputAnterior,
    30: CephalicPositions.LeftOcciputAnterior + "1",
    60: CephalicPositions.LeftOcciputAnterior + "2",
    90: CephalicPositions.LeftOcciputTransverse,
    120: CephalicPositions.LeftOcciputPosterior + "4",
    150: CephalicPositions.LeftOcciputPosterior + "5",
    180: CephalicPositions.OcciputPosterior,
    210: CephalicPositions.RightOcciputPosterior + "7",
    240: CephalicPositions.RightOcciputPosterior + "8",
    270: CephalicPositions.RightOcciputTransverse,
    300: CephalicPositions.RightOcciputAnterior + "10",
    330: CephalicPositions.RightOcciputAnterior + "11",
    360: CephalicPositions.OcciputAnterior,
  };

  // This dictionary is used to have the internal values of the rotation position by angle
  // These values are correlated to the SVG image names that represent each of those positions.
  // The number in some of them are because there are 2 positions for each type that have
  // a slightly difference in the SVG image that makes the rotation position unique but
  // within the same typing
  static rotationLabelInternalValuesByAngleAndBreechPosition: {
    [angle: number]: string;
  } = {
    0: BreechPositions.SacrumAnterior,
    30: BreechPositions.LeftSacrumAnterior + "1",
    60: BreechPositions.LeftSacrumAnterior + "2",
    90: BreechPositions.LeftSacrumTransverse,
    120: BreechPositions.LeftSacrumPosterior + "4",
    150: BreechPositions.LeftSacrumPosterior + "5",
    180: BreechPositions.SacrumPosterior,
    210: BreechPositions.RightSacrumPosterior + "7",
    240: BreechPositions.RightSacrumPosterior + "8",
    270: BreechPositions.RightSacrumTransverse,
    300: BreechPositions.RightSacrumAnterior + "10",
    330: BreechPositions.RightSacrumAnterior + "11",
    360: BreechPositions.SacrumAnterior,
  };
}
