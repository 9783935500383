import React from "react";
import { useCallback } from "react";
import { Route, Routes } from "react-router-dom";
import styled, { ThemeProvider } from "styled-components";
import {
  DropdownContentContainer,
  HorizontalCard,
  ToastProvider,
  ToastTextContainer,
} from "@laerdal/life-react-components";
import "./App.css";
import CalculatorView from "./pages/CalculatorView";
import RotateDeviceIcon from "./resources/images/rotate-device-icon.svg";
import NavigationBar from "./shared/components/NavigationBar";
import TabGroup, { TabItem } from "./shared/components/TabGroup";
import useScreenOrientation from "./shared/hooks/useScreenOrientation";
import useLabourViewStore from "./shared/hooks/useLabourViewStore";
import ProgressView from "./pages/ProgressView";

const CustomizedContainer = styled.div`
  ${DropdownContentContainer} {
    min-width: 230px;
  }
  ${ToastTextContainer} {
    width: 528px;
  }
`;

function App() {
  // Global state managers

  // State manager for tracking if the player mode is active.
  const isPlayerInProgress = useLabourViewStore(
    (state) => state.isPlayerModeActive
  );

  // State manager to control whether the date-time picker is active
  const isDateTimePickerActive = useLabourViewStore(
    (state) => state.isDateTimePickerActive
  );

  // Others
  const screenOrientation = useScreenOrientation();

  // Style components blank theme
  const theme = {};

  // Checks if the screen orientation is in landscape mode
  const IsOrientationLandscape = useCallback(() => {
    return (
      screenOrientation === "landscape-primary" ||
      screenOrientation === "landscape-secondary"
    );
  }, [screenOrientation]);

  const items: TabItem[] = [
    {
      content: "Tidspunkt for langsom fremgang",
      disabled: false,
      key: "tab1",
      to: "/",
    },
    {
      content: "Visualisering av fremgang",
      disabled: false,
      key: "tab2",
      to: "/progressview",
    },
  ];

  return (
    <>
      <ThemeProvider theme={theme}>
        <CustomizedContainer>
          <ToastProvider>
            {isPlayerInProgress && <div className="app-overlay"></div>}
            {isDateTimePickerActive && <div className="app-overlay"></div>}
            {IsOrientationLandscape() && (
              <div className="main">
                <NavigationBar />
                <div style={{ display: "flex", flex: "1" }}>
                  <div className="main-container">
                    <div className="tab-group-container">
                      <TabGroup
                        children={items}
                        initialSelected={items[0].key}
                      />
                    </div>
                    <Routes>
                      <Route path="/" element={<CalculatorView />} />
                      <Route path="/progressview" element={<ProgressView />} />
                    </Routes>
                  </div>
                </div>
              </div>
            )}
            {!IsOrientationLandscape() && (
              <>
                <div className="rotate-card-overlay"></div>
                <div className="rotate-card-container">
                  <HorizontalCard
                    title={"Vennligst snu nettbrettet ditt"}
                    description={
                      "Dette innholdet er optimalisert for landskapsmodus"
                    }
                    icon={React.createElement(
                      "img",
                      { src: RotateDeviceIcon, height: "48px", width: "48px" },
                      null
                    )}
                  />
                </div>
              </>
            )}
          </ToastProvider>
        </CustomizedContainer>
      </ThemeProvider>
    </>
  );
}

export default App;
