import { useEffect } from "react";
import {
  LocalStorageKey,
  refreshLabourDataFromLocalStorage,
  useLocalStorage,
} from "../helpers/LocalStorage";
import useLabourViewStore from "./useLabourViewStore";
import { LabourProgressDto } from "../dtos/LabourProgressDto";

export const useLabourProgressData = () => {
  // Initialize labour progress data from local storage
  const [labourProgressData, setLabourProgressData] = useLocalStorage(
    LocalStorageKey.LabourProgressDataArray,
    []
  );

  // Extract relevant state from the labour view store
  const {
    babyBodyPosition,
    babyHeadPosition,
    babyDescentLevel,
    cervixOpening,
    babyBodyRotation,
    refreshLabourProgressDataFromStorage,
    setRefreshLabourProgressDataFromStorage,
  } = useLabourViewStore((state) => ({
    babyBodyPosition: state.babyBodyPosition,
    babyHeadPosition: state.babyHeadPosition,
    babyDescentLevel: state.babyDescentLevel,
    cervixOpening: state.cervixOpening,
    babyBodyRotation: state.babyBodyRotation,
    refreshLabourProgressDataFromStorage:
      state.refreshLabourProgressDataFromStorage,
    setRefreshLabourProgressDataFromStorage:
      state.setRefreshLabourProgressDataFromStorage,
  }));

  // Effect to refresh labour progress data from local storage when flag is set
  useEffect(() => {
    if (refreshLabourProgressDataFromStorage) {
      // Update labour progress data with refreshed data from local storage
      setLabourProgressData(refreshLabourDataFromLocalStorage());
      // Reset the refresh flag after updating data
      setRefreshLabourProgressDataFromStorage(false);
    }
  }, [
    refreshLabourProgressDataFromStorage,
    setLabourProgressData,
    setRefreshLabourProgressDataFromStorage,
  ]);

  // Function to register new labour progress data
  const registerData = () => {
    const newLabourProgressInstance: LabourProgressDto = {
      id: labourProgressData.length + 1,
      babyBodyPosition,
      babyBodyDescentLevel: babyDescentLevel,
      babyBodyRotation,
      babyHeadPosition,
      cervixOpeningLevel: cervixOpening,
    };

    // Add new labour progress instance to the existing data
    setLabourProgressData([...labourProgressData, newLabourProgressInstance]);
    // Set flag to trigger refresh from local storage
    setRefreshLabourProgressDataFromStorage(true);
  };

  return {
    labourProgressData,
    registerData,
  };
};
