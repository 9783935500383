import React from "react";
import { ReactComponent as Centered } from "../../resources/images/baby-birth-position/Synclitic.svg";
import { ReactComponent as FrontAsynclitic } from "../../resources/images/baby-birth-position/FrontAsynclitic.svg";
import { ReactComponent as BackAsynclitic } from "../../resources/images/baby-birth-position/BackAsynclitic.svg";
import { ReactComponent as Breech } from "../../resources/images/baby-birth-position/Breech.svg";

interface Props {
  position: string;
}

const babyBirthPosition: { [key: string]: React.ComponentType } = {
  Sentrert: Centered,
  Fremre: FrontAsynclitic,
  Bakre: BackAsynclitic,
  Seteleie: Breech,
};

const BabyBirthPosition = ({ position }: Props) => {
  const BirthPositionComponent = babyBirthPosition[position];

  if (!BirthPositionComponent) {
    console.log(`No component found for position: ${position}`);
    return null;
  }

  return <BirthPositionComponent />;
};

export default BabyBirthPosition;
